// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';
import AOS from 'aos';
import '../../node_modules/aos/dist/aos.css';

AOS.init({
    duration: 600,
    once: true,
});

document.addEventListener("DOMContentLoaded", function(event) {
    // add class active to dropdown-item
    const dropdownItems = document.querySelectorAll('.dropdown-item');
    const bsOffcanvas = new bootstrap.Offcanvas('.navbar__offcanvas')
    dropdownItems.forEach(e => {
        e.classList.remove('active');
        e.addEventListener('click', () => {
            // add class active dropdown-item on click
            dropdownItems.forEach(f => {
                f.classList.remove('active');
            })
            e.classList.add('active');

            // close navbar dropdown-item on click
            bsOffcanvas.hide();
        })

    })

    // contact form
    const contactForm = document.querySelector(".form .wpcf7-form");
    const contactThanksModal = document.querySelector('#contactThanksModal');
    const wpfc7Response = document.querySelector('.wpcf7-response-output');
    
    if(contactForm) {
        var contactThanksModalBs = new bootstrap.Modal(contactThanksModal, { keyboard: false });
        
        contactForm.addEventListener('wpcf7mailsent', function (event) {
            contactThanksModalBs.show();
        });
    }
});